


















import moment, {Moment} from 'moment';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

export interface DayModel {
  date: Moment;
  selected?: boolean;
  inRange?: boolean;
  today?: boolean;
  inCurrentM?: boolean;
  disabled?: boolean;
}

@Component({})
export default class DatePicker extends Vue {
  weekdays = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  currentDate = moment();
  currentWeeks: DayModel[][] = [];
  selectedDay!: DayModel;
  selectedDaySecond!: DayModel;

  @Prop(String) readonly minDate!: string;
  @Prop(String) readonly maxDate!: string;
  @Prop() readonly value!: string | {start: string; end: string};
  @Prop(Boolean) readonly rangePicker!: boolean;
  @Prop(String) readonly currentMonth!: string;

  @Watch('value') onValueChanged() {
    this.initDatePicker();
  }

  @Watch('currentMonth') onCurrentMonthChanged() {
    this.currentDate = moment(this.currentMonth);
    this.initDatePicker();
  }

  mounted() {
    this.currentDate = moment(this.currentMonth);
    this.initDatePicker();
  }

  initDatePicker() {
    if (typeof this.value === 'string') {
      this.selectedDay = {date: moment(this.value)};
    } else {
      this.selectedDay = {date: moment(this.value.start)};
      if (this.value.end) {
        this.selectedDaySecond = {date: moment(this.value.end)};
      } else {
        this.selectedDaySecond = null as any;
      }
    }
    this.buildCalendar();
  }

  selectDate(day: DayModel) {
    if (!day.disabled && day.inCurrentM) {
      if (this.rangePicker) {
        if (this.selectedDay && !this.selectedDaySecond && day.date.isAfter(this.selectedDay.date)) {
          this.selectedDaySecond = day;
        } else {
          this.selectedDay = day;
          this.selectedDaySecond = null as any;
        }
      } else {
        this.selectedDay = day;
      }
      this.currentWeeks.forEach((week) => {
        week.forEach((day) => {
          if (this.rangePicker && this.selectedDaySecond) {
            if (
              !day.date.isSame(this.selectedDay.date, 'day') &&
              !day.date.isSame(this.selectedDaySecond.date, 'day')
            ) {
              day.selected = false;
            }
            day.inRange = day.date.isBetween(this.selectedDay.date, this.selectedDaySecond.date);
          } else if (day.date !== this.selectedDay.date) {
            day.selected = false;
            day.inRange = false;
          }
        });
      });
      day.selected = true;
      this.$emit(
        'dateChanged',
        this.rangePicker
          ? {
              start: this.selectedDay.date.format('YYYY-MM-DD'),
              end: this.selectedDaySecond ? this.selectedDaySecond.date.format('YYYY-MM-DD') : null,
            }
          : day.date.format('YYYY-MM-DD'),
      );
    }
  }

  changeMonth(direction: number) {
    this.currentDate =
      direction > 0 ? moment(this.currentDate).add(1, 'month') : moment(this.currentDate).subtract(1, 'month');
    this.buildCalendar();
    this.$emit('viewChanged', direction);
  }

  buildCalendar(): void {
    const currentDates = this.buildWeek(this.currentDate);
    const currentWeeks: DayModel[][] = [];
    while (currentDates.length > 0) {
      currentWeeks.push(currentDates.splice(0, 7));
    }
    this.currentWeeks = currentWeeks;
  }

  buildWeek(currentMoment: Moment): DayModel[] {
    let firstOfMonth = moment(currentMoment)
      .startOf('month')
      .day();
    firstOfMonth = firstOfMonth === 0 ? 6 : firstOfMonth - 1;
    const firstDayOfGrid = moment(currentMoment)
      .startOf('month')
      .subtract(firstOfMonth, 'days');
    const start = moment(firstDayOfGrid)
      .startOf('isoWeek')
      .date();
    const month = currentMoment.month();
    const days: DayModel[] = [];
    for (let i = start; i < start + 42; i++) {
      const day = moment(firstDayOfGrid).date(i);
      days.push({
        date: day,
        selected: this.isSelected(day),
        inRange: this.isInRange(day),
        today: this.isToday(day),
        inCurrentM: day.month() === month,
        disabled: this.isDisabled(day),
      });
      if (day.isSameOrAfter(moment(currentMoment).endOf('month'), 'day')) {
        break;
      }
    }
    return days;
  }

  isToday(date: Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  isSelected(date: Moment): boolean {
    return this.selectedDay
      ? moment(this.selectedDay.date).isSame(moment(date), 'day')
        ? true
        : this.selectedDaySecond
        ? moment(this.selectedDaySecond.date).isSame(moment(date), 'day')
        : false
      : false;
  }

  isInRange(date: Moment): boolean {
    return this.selectedDay && this.selectedDaySecond
      ? date.isSameOrBefore(this.selectedDaySecond.date, 'day') && date.isSameOrAfter(this.selectedDay.date, 'day')
      : false;
  }

  isDisabled(date: Moment): boolean {
    if (this.minDate && date.isBefore(moment(this.minDate), 'day')) {
      return true;
    } else if (this.maxDate && date.isAfter(moment(this.maxDate), 'day')) {
      return true;
    }
    return false;
  }

  dayClass(day) {
    return {
      mute: !day.inCurrentM || day.disabled,
      today: day.today,
      selected: day.selected,
      'in-range': day.inRange,
      second: this.selectedDaySecond && day.date.isSame(this.selectedDaySecond.date, 'day'),
    };
  }
}
